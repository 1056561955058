//客户/供应商下拉树
<template>
    <div :class="{'hidden':hidden}"
         v-loading="isloading"
         class="el-row">
        <selectTree ref="selectTree"
                    :treeData="data"
                    v-loading="isloading"
                    :defaultProps="defaultProps"
                    :searchForm="searchForm"
                    :placeholderStr="placeholderStr"
                    :showCheckbox="showCheckbox"
                    :shouldClean="shouldClean"
                    :checkedInfo="checkedInfo"
                    :connectUserOrProvideTag="connectUserOrProvideTag"
                    :expandOnClickNode="expandOnClickNode"
                    @handClear="handClear"
                    :disabled="disabled"
                    :needClean="needClean"
                    :filterText="filterText"
                    :isAppendToBody="isAppendToBody"
                    @treeSelectNodeClick="treeSelectNodeClick"></selectTree>
    </div>
</template>

<script>
import selectTree from "@/components/selectTree";
import baseManageApi from "@/api/baseManageApi.js";
export default {
    name: "customerOrProviderSelectTree",
    components: { selectTree },
    props: {
        defaultProps: {
            type: Object,
            default() {
                return {
                    children: "children",
                    label: "label",
                };
            },
        },
        searchForm: {
            type: Object,
            default() {
                return {
                    label: "",
                    value: "",
                };
            },
        },
        placeholderStr: {
            type: String,
            default: "请选择",
        },
        hidden: {
            type: Boolean,
            default: false,
        },
        // 是否禁用
        disabled: {
            type: Boolean,
            default: false,
        },
        //是否显示多选
        showCheckbox: {
            type: Boolean,
            default: false,
        },
        // 回显选中的值
        checkedInfo: {
            type: Object,
            default() {
                return {
                    label: "",
                    value: "",
                };
            },
        },
        //组织冒泡关闭
        expandOnClickNode: {
            type: Boolean,
            default: false,
        },


        //当前筛选类型 PROVIDER：供应商信息  CUSTOMER：客户信息
        type: {
            type: String,
            default: "CUSTOMER",
        },

        // 是否需要清空按钮  只能用于单选  多选不适用
        shouldClean: {
            type: Boolean,
            default: false,
        },

        // 特殊标记 关联供应商和客户处使用  标记是否已被关联
        connectUserOrProvideTag: {
            type: Boolean,
            default: false,
        },

        // 对数进行筛选
        filterText: {
            type: String,
            default: "",
        },
        // 是否把下拉选项插入到body
        isAppendToBody:{
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            data: [], //树数据
            isloading: false,
        };
    },
    mounted() {
        this.getOrgList();
    },
    computed: {},
    watch: {},
    methods: {
        // 点击树
        treeSelectNodeClick(data) {
            this.$emit("treeSelectNodeClick", data);
        },

        //获取客户/供应商下拉树
        getOrgList() {
            this.isloading = true;
            return baseManageApi
                .getCustomerCompanyTree({
                    type: this.type,
                })
                .then((res) => {
                    this.isloading = false;
                    this.data = res.content;
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        // 清空选择
        cleanSelect() {
            this.$refs.selectTree.cleanSelect();
        },

        //清空选择项 事件回调
        handClear() {
            this.$emit("handClear", true);
        },
    },
};
</script>

<style scoped>
.pagination-container {
    background: #fff;
    margin-top: 20px;
    /* padding: 32px 16px; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.pagination-container.hidden {
    display: none;
}
</style>
