<template>
    <div :class="{'hidden':hidden,
    'isScorll': isScorll}"
         class="el-row">
        <el-select v-model="searchForm.label"
                   style="width:100%"
                   ref="selectProjectCust"
                   :filterable="needFilterTree"
                   :filter-method="handleFilter"
                   :disabled="disabled"
                   :clearable="shouldClean"
                   @clear="handClear"
                   :popper-append-to-body="isAppendToBody"
                   :placeholder="placeholderStr">
            <!-- clearable
                   @clear="cleanSelect" -->
            <el-option :value="searchForm.value"
                       :label="searchForm.label"
                       class="optHeight">
                <el-tree class="filter-tree widealTree"
                         :data="treeData"
                         :props="defaultProps"
                         :show-checkbox="showCheckbox"
                         node-key="id"
                         @node-click="handleNodeClick"
                         @check="handleNodeCheck"
                         :default-expand-all="false"
                         :filter-node-method="filterNode"
                         :expand-on-click-node="expandOnClickNode"
                         ref="tree">
                    <!--  特殊标记 关联供应商和客户处使用  标记是否已被关联 -->
                    <span class="custom-tree-node"
                          v-if="connectUserOrProvideTag"
                          slot-scope="{ node,data }">
                        <span>{{ node.label }}</span>
                        <span style="float:right;">
                            <el-tag size="mini"
                                    v-if="!data.relateCompanyId"
                                    type="success">未关联</el-tag>
                        </span>
                    </span>
                    <span v-else
                          style="font-weight:500"
                          slot-scope="{ node }">
                        {{ node.label }}
                    </span>

                </el-tree>

            </el-option>
        </el-select>
    </div>
</template>

<script>
export default {
    name: "selectTree",
    props: {
        treeData: {
            required: true,
            type: Array,
        },
        defaultProps: {
            type: Object,
            default() {
                return {
                    children: "children",
                    label: "label",
                };
            },
        },
        searchForm: {
            type: Object,
            default() {
                return {
                    label: "",
                    value: "",
                };
            },
        },
        placeholderStr: {
            type: String,
            default: "请选择",
        },
        hidden: {
            type: Boolean,
            default: false,
        },
        //是否显示多选
        showCheckbox: {
            type: Boolean,
            default: false,
        },
        // 回显选中的值
        checkedInfo: {
            type: Object,
            default() {
                return {
                    label: "",
                    value: "", //只需要填写vulue来回显进行匹配 一般默认为id
                };
            },
        },
        //组织冒泡关闭
        expandOnClickNode: {
            type: Boolean,
            default: false,
        },
        //是否需要清空
        needClean: {
            // type: Boolean,
            default: false,
        },
        // 是否需要筛选树
        needFilterTree: {
            type: Boolean,
            default: true,
        },

        // 对数进行筛选
        filterText: {
            type: String,
            default: "",
        },
        // 是否禁用
        disabled: {
            type: Boolean,
            default: false,
        },

        // 是否需要清空按钮  只能用于单选  多选不适用
        shouldClean: {
            type: Boolean,
            default: false,
        },

        // 特殊标记 关联供应商和客户处使用  标记是否已被关联
        connectUserOrProvideTag: {
            type: Boolean,
            default: false,
        },
        // 是否需要滚动条
        isScorll: {
            type: Boolean,
            default: false,
        },
        // 是否把下拉选项插入到body
        isAppendToBody: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            checkIds: "",
        };
    },
    computed: {},
    watch: {
        checkedInfo: {
            deep: true,
            immediate: true,
            handler: function (newval) {
                if (this.checkIds.value === newval.value || !newval.value) {
                    if (!newval.value) {
                        this.searchForm.label = "";
                        this.searchForm.value = "";
                    }
                    return;
                }
                let val = (this.checkIds = this.commonJs.deepCopy(newval));
                this.$nextTick(() => {
                    //单选/复选
                    this.showCheckbox
                        ? this.$refs.tree.setCheckedKeys(
                              val.value.split ? val.value.split(",") : val.value
                          )
                        : this.$refs.tree.setCurrentKey(val.value);
                    setTimeout(() => {
                        this.searchForm.label = "";
                        this.findLabelById(
                            this.treeData,
                            val.value.split ? val.value.split(",") : val.value
                        ); //labe回显
                        this.searchForm.label = this.searchForm.label
                            .split(",")
                            .filter((v) => v !== "")
                            .join(","); //清除最后一个,号
                        this.searchForm.value = val.value;
                        val.label ? (this.searchForm.label = val.label) : "";
                    }, 200);
                });
            },
        },
        //清空筛选条件
        needClean: {
            deep: true,
            immediate: true,
            handler: function (newval) {
                newval && this.cleanSelect();
            },
        },

        /* 树的筛选 */
        filterText(val) {
            this.$refs.tree.filter(val);
        },
    },
    methods: {
        // 触发筛选树
        handleFilter(val) {
            this.$refs.tree.filter(val);
        },
        // 筛选 树
        filterNode(value, data) {
            if (!value || !data.label) return true;
            return data.label.indexOf(value) !== -1;
        },
        //下拉树的选择
        handleNodeClick(node) {
            if (this.showCheckbox) {
                return;
            }
            this.searchForm.label = node.label;
            this.searchForm.value = node.id;
            this.$refs.selectProjectCust.blur();
            this.$emit("treeSelectNodeClick", node);
        },
        //多选时的选中事件
        handleNodeCheck() {
            let checkedNodes = this.$refs.tree.getCheckedNodes();
            let checkNode = checkedNodes
                // .filter((v) => !v.hasOwnProperty("children"))
                .map((v) => v.name);
            this.searchForm.label = checkNode.join(",");
            this.$emit("treeSelectNodeClick", checkedNodes);
        },
        // 清空选择
        cleanSelect() {
            this.$refs.tree.setCheckedKeys([]); // 清除选中数组
            this.$refs.tree.setCurrentKey(null); // 清除高亮
            this.searchForm.label = "";
            this.searchForm.value = "";
            // 触发筛选树
            this.handleFilter("");
            // TODO 这里之后需要处理传递
            // this.$emit("treeSelectNodeClick", null);
        },

        // 递归匹配label
        findLabelById(data, id) {
            data &&
                data.forEach((v) => {
                    if (id.includes(v.id.toString())) {
                        this.$set(
                            this.searchForm,
                            "label",
                            this.searchForm.label + v.label + ","
                        );
                    }
                    v.hasOwnProperty("children")
                        ? this.findLabelById(v.children, id)
                        : "";
                });
        },

        //清空选择项 事件回调
        handClear() {
            this.cleanSelect();
            this.$emit("handClear", true);
        },

        matchingId() {},
    },
};
</script>

<style scoped>
.pagination-container {
    background: #fff;
    margin-top: 20px;
    /* padding: 32px 16px; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.pagination-container.hidden {
    display: none;
}
.optHeight {
    min-width: 300px;
    height: 200px;
    overflow: auto;
    background-color: #fff;
    padding-bottom: 20px;
}
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}
.el-select-dropdown__item {
    padding: 0px !important;
}
.widealTree {
    width: 100%;
    box-sizing: border-box;
}
</style>
<style>
.widealTree .el-tree-node {
    padding: 0 10px;
    box-sizing: border-box;
}
</style>
