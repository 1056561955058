// 项目管理  projectManage
// import request from '@/utils/request'
import {
    get,
    postForm,
    // post,
    // postForm,
    postJson,
} from "./http/http";

const httpServer = {
    // 项目列表各状态计数
    projectManageCount(params) {
        return postJson("/project/project/tableCount", params);
    },
    // 项目列表查询
    // 归档项目列表查询
    // 关联项目查询
    projectManageList(params) {
        return postJson("/project/project/table", params);
    },
    // 归档项目列表各状态计数
    archivedItemsCount(params) {
        return postJson("/project/project/tableGdCount", params);
    },
    // 新增项目id获取
    getId() {
        return postJson("/project/project/addProjectId");
    },
    // 查询项目详情
    getDetails(id) {
        return get(`/project/project/findById/${id}`);
    },
    // 查询项目下的设备采购管理
    getDeviceBuyList(params) {
        return get(`/task/task/countDeviceByProjectId`, params);
    },
    // 查询项目下的设备采购管理总表
    getAllDeviceBuyList(params) {
        return get(`/emp/purchase-order/findModelListByProjectId`, params);
    },
    // 导出项目下的设备采购管理总表
    exportAllDeviceBuyList(params) {
        return get(`/task/task/exportModelListByProjectId`, params);
    },
    // 项目 保存 跳过 完成 废止 搁置
    saveProject(params) {
        return postJson("/project/project/save", params);
    },
    // 查询采购记录
    purchaseOrderLis(params) {
        return postJson("/emp/purchase-order/table", params);
    },
    // 获取储备项目下拉列表
    selectReserveProjectList(params) {
        return postJson("/reserveproject/reserve-project/recordTable", params);
    },
    // 删除项目
    delProject(params) {
        return get(`/project/project/delete`, params);
    },
    // 保存增补项目关系
    linkItemFriendship(params) {
        return postJson(
            "/project/project-supplement-relevance/saveProjectSupplement",
            params
        );
    },
    // 删除增补项目关系
    dellinkItemFriendship(params) {
        return get("/project/project-supplement-relevance/delete", params);
    },
    // 增补项目更新金额
    updateMoney(params) {
        return postForm(
            "/project/project-supplement-relevance/updateSupplementMoney",
            params
        );
    },
    // 获取项目权限
    getProjectRole(params) {
        return get("/base/role-menu/findCurrentByProjectId", params);
    },

    // 查询部署管理列表
    getDeploymentManageList(params) {
        return get(`/deploy/countDeviceByProjectId`, params);
    },

    // 删除部署实施
    delDeployment(params) {
        return postJson("/deploy/delete", params);
    },
};

export default httpServer;
