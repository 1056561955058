// 选择产品项目 selectProject 已有新的组件comSelectProject，逐步转移中
// 使用示例
// <select-project @select="selectSystem"></select-project>
// 项目产品的选择
// selectSystem(data) {
//     this.searchForm.systemId = data.value;
// },

<template>

    <div class="selectProject">
        <el-select v-model="data"
                   placeholder="请选择项目产品"
                   @change="select"
                   clearable>
            <el-option v-for="item in systemInfoIdOpts"
                       :key="item.value"
                       :label="item.label"
                       :value="{
                           label: item.label,
                           value: item.value
                       }">
            </el-option>
        </el-select>
    </div>

</template>

<script>
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "selectProject",

    props: {
        chooseData: {
            type: String,
            default: "",
        },
    },

    components: {},

    data() {
        return {
            systemInfoIdOpts: [], // 软件信息下拉
            // 选中的值
            data: {
                label: "全部",
                value: "",
            },
        };
    },

    created() {},

    mounted() {
        // 查询软件信息下拉
        this.getSystemInfoList();
    },

    methods: {
        // 查询软件信息下拉
        getSystemInfoList() {
            commonAPI
                .getSystemInfoList()
                .then((res) => {
                    let data = res.content;
                    let opt = data.map((val) => {
                        return {
                            label: val["label"],
                            value: val["code"],
                        };
                    });
                    this.systemInfoIdOpts = this.systemInfoIdOpts.concat(opt);
                })
                .catch(() => {});
        },
        // 选择了项目产品
        select() {
            this.$emit("select", this.data);
        },
        // 清空选择
        cleanSelect() {
            (this.data = {
                label: "全部",
                value: "",
            }),
                this.select();
        },
    },

    computed: {},

    watch: {
        // 数据回显
        chooseData() {
            if (this.chooseData && this.systemInfoIdOpts) {
                let filterData = this.systemInfoIdOpts.filter((item) => {
                    return String(item.value) === String(this.chooseData);
                });
                if (filterData.length > 0) {
                    this.data = filterData[0];
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
