// 归档项目
<template>

    <div class='archivedItems baseBg'>
        <div class="listBox mainWhiteBg">
            <el-tabs v-model="activeName"
                     ref="searchTab"
                     class="searchTab"
                     @tab-click="handleClick">
                <el-tab-pane name="全部归档"
                             v-if="commonJs.isShowBtn(detailRole, 'Tab_全部归档')">
                    <div class="tabsLabel"
                         @click="commonJs.setTabId(detailRole,'Tab_全部归档')"
                         slot="label">
                        <el-badge :type="activeName == '全部归档' ? 'primary' : 'warning'"
                                  :value="counts.全部归档"
                                  class="item">
                            全部归档
                        </el-badge>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="完成"
                             v-if="commonJs.isShowBtn(detailRole, 'Tab_完成')">
                    <div class="tabsLabel"
                         @click="commonJs.setTabId(detailRole,'Tab_完成')"
                         slot="label">
                        <el-badge :type="activeName == '完成' ? 'primary' : 'warning'"
                                  :value="counts.完成"
                                  class="item">
                            完成
                        </el-badge>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="废止"
                             v-if="commonJs.isShowBtn(detailRole, 'Tab_废止')">
                    <div class="tabsLabel"
                         @click="commonJs.setTabId(detailRole,'Tab_废止')"
                         slot="label">
                        <el-badge :type="activeName == '废止' ? 'primary' : 'warning'"
                                  :value="counts.废止"
                                  class="item">
                            废止
                        </el-badge>
                    </div>
                </el-tab-pane>
            </el-tabs>

            <div class="tableOpportunity">
                <!-- 搜索条件 -->
                <div class="searchForm">
                    <el-form :model="searchForm"
                             ref="searchForm"
                             :rules="searchFormrules"
                             :inline="true">
                        <el-form-item label="项目名称"
                                      prop="name">
                            <el-input v-model="searchForm.name"
                                      placeholder="请输入项目名称"></el-input>
                        </el-form-item>
                        <el-form-item label="客户名称"
                                      prop="customerName">
                            <customer-or-provider-select-tree ref="selectTree"
                                                              type="CUSTOMER"
                                                              :shouldClean="true"
                                                              @handClear="handClear"
                                                              @treeSelectNodeClick="selectTreeCustClick"></customer-or-provider-select-tree>
                        </el-form-item>
                        <el-form-item label="项目产品"
                                      prop="systemId">
                            <select-project ref="selectProject"
                                            @select="selectSystem"></select-project>
                        </el-form-item>
                        <el-form-item label="是否增补项目"
                                      prop="supplemenFlag">
                            <el-select v-model="searchForm.supplemenFlag"
                                       placeholder="请选择是否增补项目"
                                       clearable>
                                <el-option v-for="item in supplemenFlagOpts"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary"
                                       @click="getList">搜索</el-button>
                            <el-button @click="resetSearchForm">清除</el-button>
                        </el-form-item>
                    </el-form>
                </div>

                <!-- 列表 -->
                <div class="tableBox">
                    <el-table :data="tableData.data"
                              :height="tableHeight"
                              border
                              stripe>
                        <el-table-column type="index"
                                         label="序号"
                                         align="center"
                                         width="50">
                        </el-table-column>
                        <el-table-column v-for="col in tableData.columns"
                                         align="center"
                                         :prop="col.id"
                                         :key="col.id"
                                         :label="col.label"
                                         :min-width="col.width">
                        </el-table-column>
                        <el-table-column prop="id"
                                         label="操作"
                                         align="center"
                                         min-width="110">
                            <template slot-scope="scope">
                                <el-link type="primary"
                                         v-if="commonJs.isShowBtn(detailRole, 'Btn_详情')"
                                         @click="details(scope.row)">详情</el-link>
                                <el-link type="primary"
                                         v-if="commonJs.isShowBtn(detailRole, 'Btn_管理任务')"
                                         @click="taskManage(scope.row)">管理任务</el-link>
                                <template v-if="commonJs.isShowBtn(detailRole, 'Btn_删除')">
                                    <el-popconfirm confirm-button-text="删除"
                                                   cancel-button-text="取消"
                                                   icon="el-icon-info"
                                                   icon-color="red"
                                                   @confirm="del(scope.row)"
                                                   title="真的要删除吗？">
                                        <el-link type="danger"
                                                 slot="reference">删除</el-link>
                                    </el-popconfirm>
                                </template>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <Pagination :page="searchForm.page"
                                :limit="searchForm.size"
                                :total="pageTotal"
                                @pagination="paginationChange" />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import selectProject from "@/components/commonSelect/selectProject.vue";
import customerOrProviderSelectTree from "@/components/selectTree/customerOrProviderSelectTree.vue";
import { Loading } from "element-ui";
import Pagination from "@/components/Pagination"; // 分页
import API from "@/api/projectManage.js";
export default {
    name: "archivedItems",

    props: [],

    components: {
        Pagination,
        "customer-or-provider-select-tree": customerOrProviderSelectTree,
        "select-project": selectProject,
    },

    data() {
        return {
            activeName: "全部归档",
            searchForm: {
                name: "", // 项目名称
                customerName: "", // 客户名称
                systemId: "", // 项目产品
                supplemenFlag: "", // 是否增补项目
                stage: "5", // 项目进行阶段：1-项目规划,2-实施阶段,3-竣工验收,4-项目核算,5-归档
                status: "", // 项目状态：1-进行中,2-搁置,3-废止,4-完成
                page: 1,
                size: 20,
            },
            pageTotal: 0,
            counts: {
                // 列表数量
                全部归档: 0,
                废止: 0,
                完成: 0,
            },
            searchFormrules: {},
            supplemenFlagOpts: [
                { label: "全部", value: "" },
                { label: "是", value: "是" },
                { label: "否", value: "否" },
            ], // 是否增补项目下拉
            tableData: {
                data: [],
                columns: [
                    { id: "name", label: "项目名称", width: "100" },
                    { id: "customerName", label: "客户名称", width: "100" },
                    { id: "managerName", label: "项目经理", width: "100" },
                    { id: "money", label: "项目规模（元）", width: "100" },
                    { id: "status", label: "项目状态", width: "100" },
                    { id: "ssPlan", label: "实施计划", width: "100" },
                    { id: "projectSystem", label: "项目产品", width: "100" },
                    {
                        id: "planFinishTime",
                        label: "项目完成时间",
                        width: "100",
                    },
                    {
                        id: "supplemenFlag",
                        label: "是否增补项目",
                        width: "100",
                    },
                ],
            },
            detailRole: {}, //权限数据
        };
    },

    created() {
        this.commonJs.getFistTabId(this.detailRole, [
            "Tab_全部归档",
            "Tab_完成",
            "Tab_废止",
        ]);
    },

    mounted() {
        this.commonJs.getFistTabId(this.detailRole, [
            "Tab_全部归档",
            "Tab_完成",
            "Tab_废止",
        ]);
        if (window.localStorage.getItem("archivedItemsTab")) {
            this.activeName = window.localStorage.getItem("archivedItemsTab");
            window.localStorage.removeItem("archivedItemsTab");
        }
        this.commonJs.getCurrentButtonByMenuId(
            window.localStorage.getItem("routerId")
        );
        // 归档项目-列表数量
        this.archivedItemsCount();
        // 查询列表
        this.getList();
    },

    methods: {
        // 归档项目-列表数量
        archivedItemsCount() {
            let loading = Loading.service({
                target: document.querySelector(".el-tabs"),
            });
            API.archivedItemsCount({ order: "DESC" })
                .then((res) => {
                    this.counts = res.content;
                    this.counts["全部归档"] = res.content.statusAll;
                    this.counts["废止"] = res.content.status3;
                    this.counts["完成"] = res.content.status4;
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
        //项目客户的选择
        selectTreeCustClick(node) {
            this.searchForm.customerName = node.label;
        },
        //项目客户的清空
        handClear() {
            this.searchForm.customerName = "";
        },
        // 项目产品的选择
        selectSystem(data) {
            this.searchForm.systemId = data.value;
        },
        // 切换tabs
        handleClick() {},
        // 查看详情
        details(row) {
            window.localStorage.setItem("archivedItemsTab", this.activeName);
            this.$router.push({
                path: "/projectManage/projectManageDetails",
                query: {
                    status: "编辑",
                    id: row.id,
                },
            });
        },
        // 清空表单
        resetSearchForm() {
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    // 清除下拉树选择
                    this.$refs.selectTree.cleanSelect();
                    this.$refs.selectProject.cleanSelect();
                    // 查询列表
                    this.getList();
                }
            });
        },
        // 跳转到管理任务页面
        taskManage(item) {
            this.$router.push({
                path: "/taskManage/itemTask",
                query: {
                    id: item.id,
                    needGoBack: true,
                },
            });
        },
        // 删除
        del(row) {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API.delProject({
                id: row.id,
            })
                .then(() => {
                    this.$message({ message: "删除成功！", type: "success" });
                    // 查询列表
                    this.getList();
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 查询列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API.projectManageList(this.searchForm)
                .then((res) => {
                    loading.close();
                    let STATUS = ["进行中", "搁置", "废止", "完成"];
                    res.content.records.forEach((item) => {
                        item.status = STATUS[item.status - 1];
                    });
                    this.tableData.data = res.content.records;
                    this.counts[this.activeName] = res.content.total;
                    this.pageTotal = res.content.total;
                })
                .catch(() => {
                    loading.close();
                });
        },
        //项目客户的选择
        projectCustClick(node) {
            this.searchForm.custUser = node.label;
            this.searchForm.custUserId = node.id;
        },
        // 分页改变
        paginationChange(e) {
            this.searchForm.size = e.size;
            this.searchForm.page = e.page;
            this.getList();
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 245 });
        },
        //权限数据
        roleInfo() {
            return this.$store.state.role.roleInfo;
        },
    },

    watch: {
        roleInfo: {
            deep: true,
            immediate: true,
            handler: function (val) {
                this.detailRole = this.commonJs.tabIsAlive(
                    val,
                    "归档项目_列表"
                );
            },
        },
        // 切换tabs
        activeName() {
            if (window.localStorage.getItem("archivedItemsTab")) {
                window.localStorage.removeItem("archivedItemsTab");
            }
            // 只有全部归档的时候需要stage
            this.searchForm.stage = "5";
            this.searchForm.status = "";
            let data = [
                {
                    label: "全部归档",
                    value: "",
                    type: "status",
                    stage: "5",
                },
                {
                    label: "搁置",
                    value: "2",
                    type: "status",
                    stage: "",
                },
                {
                    label: "废止",
                    value: "3",
                    type: "status",
                    stage: "",
                },
                {
                    label: "完成",
                    value: "4",
                    type: "status",
                    stage: "",
                },
            ];
            let nowStep = data.filter(
                (item) => item.label === this.activeName
            )[0];
            this.searchForm[nowStep.type] = nowStep.value;
            this.searchForm["stage"] = nowStep.stage;
            this.getList();
        },
    },
};
</script>

<style lang='scss' scoped>
.searchForm {
    height: auto;
}
.archivedItems ::v-deep .el-tabs__nav-scroll {
    overflow: visible;
}
.archivedItems ::v-deep .el-tabs__nav-wrap {
    overflow: visible;
    height: 40px;
}
</style>
